import { RcLink } from '@/components/rc-link';
import { useTranslation } from '@/translation/translation';
import { PRODUCT_LOGOS_OPTIONS, generateImageUrl } from '@/utils/images-urls';
import { cn } from '@/utils/tailwind';
import { RecommendedBannerItemGtmProps, RecommendedProduct } from '../types';
import { ProductTypeToRecommendationItemTitle } from './recommended-banner-v2';

interface RecommendedProductV2Props {
  onClick: () => void;
  className: string;
  productImageUrl: string;
  recommendedProduct: RecommendedProduct;
  productType: (typeof ProductTypeToRecommendationItemTitle)[keyof typeof ProductTypeToRecommendationItemTitle];
  productName: string;
  productLink: string;
}

export function RecommendedProductV2({
  onClick,
  className,
  productImageUrl,
  recommendedProduct,
  productType,
  productName,
  productLink,
}: RecommendedProductV2Props) {
  const { t } = useTranslation();

  const dataGtm = {
    name_0: productName,
    id_0: recommendedProduct.id,
    ranking: recommendedProduct.ranking,
    score_0: recommendedProduct.score,
    type_0: recommendedProduct.type,
  } as const satisfies RecommendedBannerItemGtmProps;

  return (
    <RcLink
      aria-label={t(`Recommended product, ${productName}, ${productType}`)}
      data-gtm={JSON.stringify(dataGtm)}
      href={productLink}
      className={cn(
        'flex w-[200px] flex-col items-start lg:w-[286px]',
        className,
      )}
      onClick={onClick}
    >
      <div
        className={cn(
          'relative flex flex-col items-center justify-center overflow-hidden',
          'lg:w-[286px]',
          'lg:h-[168px]',
          'h-[120px]',
          'w-[200px]',
          'rounded-custom border border-white bg-white hover:border-black',
        )}
      >
        <img
          src={generateImageUrl({
            url: productImageUrl,
            ...PRODUCT_LOGOS_OPTIONS,
          })}
          alt=""
          className={cn('h-full w-full object-contain', {
            'object-cover': productType === 'Gift Cards',
          })}
        />
      </div>
      <div>
        <p className="line-clamp-1 text-base font-bold text-neutral-900">
          {productName}
        </p>
        <p className="line-clamp-2 text-base font-normal text-neutral-600">
          {productType}
        </p>
      </div>
    </RcLink>
  );
}
